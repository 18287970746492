<template>
  <div>
    <h1 :class="$style.branchName">
      <router-link :to="{ name: 'company' }">{{ companyName }}</router-link> {{branch.name}}
    </h1>
    <div class="row">

      <div class="col-lg-12">
        <section class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <div class="d-inline-block">
                <a-dropdown v-if="!loading" :class="$style.operationBtn">
                  <span class="ant-dropdown-link">
                    <i class="icmn-cog mr-2"/>
                    <span class="hidden-lg-down mr-2">操作</span>
                    <a-icon type="down"/>
                  </span>
                  <a-menu slot="overlay">
                    <a-menu-item>
                      <router-link :to="{ name: 'branchUpdate', params: { id: branch.id }}">
                        <i class="icmn-pencil mr-2"/>
                        <span>編集</span>
                      </router-link>
                    </a-menu-item>
                    <a-menu-item @click="showModal">
                      <i class="icmn-bin mr-2"/>
                      <span>削除</span>
                    </a-menu-item>
                  </a-menu>
                </a-dropdown>
                <a-modal
                  :title="title"
                  :visible="visible"
                  :confirm-loading="confirmLoading"
                  okText='削除'
                  okType='danger'
                  cancelText='キャンセル'
                  @ok="handleOk"
                  @cancel="handleCancel"
                />
              </div>
            </div>
            <h2>基本情報</h2>
          </div>
          <div class="card-body">
            <div v-if="loading" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <ul v-if="!loading" :class="$style.basicInfoList">
              <li><span :class="$style.infoLabel">名前: </span><span :class="$style.infoValue">{{branch.name}}</span></li>
              <li><span :class="$style.infoLabel">住所: </span><span :class="$style.infoValue">{{branch.address}}</span></li>
              <li><span :class="$style.infoLabel">備考: </span><span :class="$style.infoValue">{{branch.comment}}</span></li>
              <li><span :class="$style.infoLabel">作成日: </span><span :class="$style.infoValue">{{branch.created_at | moment}}</span></li>
              <li><span :class="$style.infoLabel">更新日: </span><span :class="$style.infoValue">{{branch.updated_at | moment}}</span></li>
            </ul>
          </div>
        </section>
      </div>
      <div class="col-lg-6">
        <belong-users-list
          :belongUsersListLoading="belongUsersListLoading"
          :users="users"
          :branchId="branch.id"
        />
      </div>
      <div class="col-lg-6">
        <vehicles-list
          :vehiclesListLoading="vehiclesListLoading"
          :vehicles="vehicles"
          :branchId="branch.id"
          :concatVehicleNameAndNo="concatVehicleNameAndNo"
        />
      </div>
    </div>
    <div class="col-lg-12">
      <drive-data-list
        target="branch"
        :targetId="branchId"
        :key="$route.fullPath + '_BranchDriveDataView'"
      />
    </div>
  </div>
</template>
<style lang="scss" module>
@import "./style.module.scss";
</style>
<script>
import Vue from 'vue'
import moment from 'moment'
import VehiclesList from '@/components/ListComponents/Vehicles'
import BelongUsersList from '@/components/ListComponents/BelongUsers'
import DriveDataList from '@/views/drive_data/list'
import { objectData } from '@/services/object'

export default {
  components: {
    VehiclesList,
    BelongUsersList,
    DriveDataList,
  },
  data() {
    return {
      branchId: this.$route.params.id,
      loading: true,
      vehiclesListLoading: true,
      belongUsersListLoading: true,
      branch: Object.assign({}, objectData.branch),
      vehicles: [],
      users: [],
      visible: false,
      title: '',
      companyName: '',
      concatVehicleNameAndNo: 1,
      confirmLoading: false,
    }
  },
  created() {
    const companyResult = Vue.prototype.$api.send('get', 'user')
    companyResult.then(response => {
      this.concatVehicleNameAndNo = response.concat_vehicle_name_and_no
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 会社情報の取得に失敗しました。',
        })
      })

    const result = Vue.prototype.$api.send('get', 'branches/' + this.$route.params.id)
    result.then(response => {
      this.loading = false
      this.branch = response
      this.companyName = response.company_name
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.loading = false
      })

    const vehicleList = Vue.prototype.$api.send('get', 'vehicles/list/' + this.$route.params.id)
    vehicleList.then(response => {
      this.vehiclesListLoading = false
      this.vehicles = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 車両の取得に失敗しました。',
        })
        this.vehiclesListLoading = false
      })

    const userList = Vue.prototype.$api.send('get', 'branches/' + this.$route.params.id + '/users')
    userList.then(response => {
      this.belongUsersListLoading = false
      this.users = response
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 所属ユーザーの取得に失敗しました。',
        })
        this.belongUsersListLoading = false
      })
  },
  methods: {
    showModal(index) {
      console.log('show Modal')
      this.title = '本当に ' + this.branch.name + ' を削除しますか？'
      this.visible = true
    },
    handleOk() {
      this.confirmLoading = true
      const result = Vue.prototype.$api.send('delete', 'branches/' + this.branch.id)
      result.then(response => {
        this.$notification['success']({
          message: this.branch.name + ' を削除しました',
        })
        this.$router.push('/company')
        this.confirmLoading = false
        this.visible = false
      })
        .catch(error => {
          this.$notification['error']({
            message: error.status + ': 削除に失敗しました。',
          })
          this.confirmLoading = false
          this.visible = false
        })
    },
    handleCancel() {
      this.visible = false
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format('YYYY/MM/DD HH:mm')
    },
  },
}
</script>

<style>
.list-name {
  font-size: 1.5rem;
}
</style>
